/**
  reset.css
 */

 html,
 body,
 div,
 span,
 applet,
 object,
 iframe,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 p,
 blockquote,
 pre,
 a,
 abbr,
 acronym,
 address,
 big,
 cite,
 code,
 del,
 dfn,
 em,
 img,
 ins,
 kbd,
 q,
 s,
 samp,
 small,
 strike,
 strong,
 sub,
 sup,
 tt,
 var,
 b,
 u,
 i,
 center,
 dl,
 dt,
 dd,
 ol,
 ul,
 li,
 fieldset,
 form,
 label,
 legend,
 table,
 caption,
 tbody,
 tfoot,
 thead,
 tr,
 th,
 td,
 article,
 aside,
 canvas,
 details,
 embed,
 figure,
 figcaption,
 footer,
 header,
 hgroup,
 menu,
 nav,
 output,
 ruby,
 section,
 summary,
 time,
 mark,
 audio,
 video,
 button,
 input {
   margin: 0;
   padding: 0;
   border: 0;
   font-size: 100%;
   font-family: 'Noto Sans KR', 'Poppins';
   font-weight: 400;
   vertical-align: baseline;
   font-style: normal;
   box-sizing: border-box;
   word-break: keep-all;
 }
 
 /* HTML5 display-role reset for older browsers */
 article,
 aside,
 details,
 figcaption,
 figure,
 footer,
 header,
 hgroup,
 menu,
 nav,
 section {
   display: block;
 }
 
 img {
   vertical-align: middle;
   border-style: none;
 }
 
 body {
   line-height: 1;
   width: 100%;
   position: relative;
 }
 
 html {
   -ms-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
   overflow-x: hidden;
   width: 100%;
 }
 
 ol,
 ul {
   list-style: none;
 }
 
 blockquote,
 q {
   quotes: none;
 }
 
 blockquote:before,
 blockquote:after,
 q:before,
 q:after {
   content: '';
   content: none;
 }
 
 table {
   border-collapse: collapse;
   border-spacing: 0;
 }
 
 a {
   color: $black;
   text-decoration: none;
   font-style: normal;
   font-family: 'Noto Sans KR', sans-serif;
   font-weight: 400;
 }
 
 a[disabled] {
   pointer-events: none;
   cursor: default;
 }
 
 a:hover,
 a:active,
 a:visited {
   text-decoration: none;
   font-style: normal;
 }
 
 input {
   border: none;
   background-image: none;
   background-color: transparent;
   -webkit-box-shadow: none;
   -moz-box-shadow: none;
   box-shadow: none;
   appearance: none;
   -moz-appearance: none;
   -webkit-appearance: none;
   margin: 0;
 
   -webkit-border-radius: 0;
 }
 
 input[type='number']::-webkit-outer-spin-button,
 input[type='number']::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }
 /*radio box*/
 input[type='radio'] {
   //display: none;
 }
 input[type='radio']::-ms-check {
   border-color: transparent;
 }
 input[type='radio']::-ms-check {
   background-color: transparent;
 }
 button {
   border: none;
   margin: 0;
   padding: 0;
   background: transparent;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   cursor: pointer;
 }
 
 select {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   border: none;
   &::-ms-expand {
     display: none;
   }
 }
 input,
 textarea,
 select,
 button {
   font-family: 'Noto Sans KR', sans-serif;
   font-weight: 400;
 }
 textarea {
   resize: none;
   width: 100%;
   box-sizing: border-box;
   -webkit-border-radius: 0;
   -webkit-appearance: none;
 }
 input[type='text']::-ms-clear {
   display: none;
 }
 
 i {
   display: inline-block;
 }
 