@import "./colors.scss";

/**
 * Global Style
 */
 
img {
  max-width: 100%;
}

 /* font */
.font-30 {
  font-size: 30px;
}
.font-bold {
  font-weight: bold;
}

h1 {
  font-size: 70px;
  font-weight: 100;
  line-height: 1.21;
}
h2 {
  padding-top: 30px;
  font-size: 28px;
  line-height: 1.29;
  letter-spacing: -0.56px;
}
h3 {
  padding-top: 47px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.27;
}
h4 {
  margin-bottom: 30px;
  line-height: 1.47;
  font-size: 17px;
  font-weight: bold;
}
h5 {
  font-size: 17px;
  font-weight: 300;
  line-height: 1.65;
  letter-spacing: -0.34px
}
h6 {
  margin-top: 9px;
  font-size: 25px;
  line-height: 1.28;
}

/* line */
.line {
  border-top: 1px solid $darkGray;
  margin-bottom: 14px;
  clear: both;
}

/* margin */
.ml-4 {
  margin-left: 4px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-6 {
  margin-right: 6px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-25 {
  margin-top: 25px;
}

/* padding */
.pt-10 {
  padding-top: 10px;
}

/* required */
.required {
  &::before {
    content: '*';
    margin-right: 5px;
    display: inline-block;
    color: #ff6741;
  }
}

/* input */
.input-text {
  width: 384px;
  padding: 10px 5px;
  border-bottom: 1px solid $lightGray;
  &::placeholder {
    color: $lightGray;
  }
  &:focus {
    outline: 0;
    border-bottom: 1px solid $black;
    transition: all .3s ease;
  }
}
.input-address {
  height: 45px;
  border: solid 1px #dedede;
  padding: 10px 10px;
}
.address {
  margin-bottom: 10px;
}
.zip-code {
  width: 100px;
  margin-right: 10px;
}
.address-base {
  width: 448px;
}
.address-detail {
  width: 300px;
}

.checkbox {
  width: 150px;
  height: 50px;
}
.checkbox .Mui-checked {
  color: $black;
  &:hover {
    background-color: transparent;
  }
}

textarea {
  display: inline-block;
  height: 175px;
  border: 1px solid $lightGray;
  padding: 22px 25px;
  font-size: 17px;
  &::placeholder {
    color: #ccc;
  }
}

.input-sub-title {
  font-size: 14px;
  font-weight: 300;
  color: #aaaaaa;
}

.input-file {
  position: absolute;
  width: 109px;
  height: 45px;
  margin-top: 25px;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.file-upload {
  position: relative;
  margin-top: 25px;
  p {
    margin-top: 13px;
    font-size: 14px;
    color: #aaaaaa;
  }
}

.upload-photo {
  position: absolute;
  width: 305px;
  height: 130px;
  margin-top: 25px;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.btn.btn-file {
  margin-top: 25px;
  margin-left: 0;
  &.delete-btn{
    color: #ff6741;
  }
}
.mypage-logo {
  width: 305px;
  height: 130px;
}
.btn-photo {
  width: 305px;
  height: 130px;
  &::before {
    content: '';
    width: 35px;
    height: 31px;
    position: absolute;
    top: 24px;
    display: inline-block;
    background: url(../assets/images/icon/icon-photo.png) no-repeat ;
  }
  .upload-text {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%);
    font-size: 13px;
    color: #c6c6c6;;
    .font-gray {
      font-size: 12px;
      color: #aaaaaa;
    }
  }
}

/* select */
.MuiFormLabel-root.select-label {
  font-size: 15px;
  line-height: 4px;
}
.select-item .MuiSelect-select {
  padding: 0;
}
.select-item .MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 0;
}

/* button */
button {
  &:focus {
    outline: 0;
  }
  h1 {
    display: none;
  }
}
.btn {
  height: 45px;
  padding: 0 15px;
  border: 1px solid $lightGray;
  font-size: 16px;
  font-weight: bold;
}

.btn-help {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  background-color: #d5d5d5;
  border-radius: 50%;
  color: $white;
  font-size: 14px;
}

.btn-close {
  position: absolute;
  bottom: -322px;
  right: 27%;
  padding: 3px 11px;
  z-index: 101;
  background: #b3b3b3;
  color: $white;
  border-radius: 5px;
  opacity: .9;
}

.btn-accordion {
  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    .icon {
      float: right;
      &::after {
        content: '';
        display: inline-block;
        width: 20px;
        height: 12px;
      }
    }
    .icon-down {
      background: url(../assets/images/icon/icon-arrow-down.png) no-repeat center center;
    }
    .icon-up {
      background: url(../assets/images/icon/icon-arrow-up02.png) no-repeat center center;
    }
  }
}

/* modal */
.dimmed {
  position: fixed;
  opacity: 0.7;
  background-color: #04070d;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}
.modal {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 1001;
  background: $white;
  margin: 0 auto;
}
.btn-cancel {
  float: right;
  &::after {
    content: '';
    float: right;
    width: 15px;
    height: 15px;
    display: block;
    background: url(../assets/images/icon/icon-cancel.png) no-repeat;
  }
  h1 {
    display: none;
  }
}

.modal-pw {
  position: absolute;
  left: 43%;
  bottom: 47px;
  padding: 26px 33px;
  width: 322px;
  height: 200px;
  border: solid 1px #dedede;
  background: $white;
  .title {
    font-size: 17px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .sub-title {
    font-size: 15px;
    line-height: 1.67;
    letter-spacing: -0.3px;
    border-bottom: none;
  }
}

/* policy */
.policy-box {
  height: 459px;
  margin: 23px 0 42px 0;
  padding: 13px 15px;
  border: 1px solid $lightGray;
  overflow-y: scroll;
  p {
    margin-bottom: 30px;
    color: $darkGray;
    line-height: 2;
  }
}

/* 안내 페이지 */
.text-area {
  h1 {
    margin-bottom: 13px;
    font-size: 70px;
    font-weight: bold;
    line-height: 1.21;
  }
  h3 {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.27;
  }
  p {
    margin: 55px 0 140px 0;
    font-size: 24px;
    line-height: 1.5;
  }
  .info {
    margin: 55px 0 95px 0;
    line-height: 1.8;
  }
}
.button-area {
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 50px;
    font-size: 17px;
    font-weight: bold;
    span {
      font-size: 20px;
    }
  }
  .btn-login {
    margin-right: 15px;
    border: solid 1px #dedede;
  }
  .btn-main {
    background-color: $mint;
    color: $white;
    border: none;
  }
  .btn2 {
    width: 401px;
    height: 73px;
    display: inline-block;
    font-size: 20px;
  }
}

/* align center  */
.align-center {
  text-align: center;
}


.left-contents {
  width: calc(100% / 2);
  float: left;
}
.right-contents {
  width: calc(100% / 2);
  float: right;
  p {
    margin-bottom: 30px;
    line-height: 1.47;
    font-size: 17px;
  }
}

/* pagination */
.pagination {
  clear: both;
  display: flex;
  justify-content: center;
  padding: 30px 0 132px 0;
  .MuiPaginationItem-page.Mui-selected {
    background-color: #32e3a9;
    color: $white;
  }
  ul {
    li {
      margin-right: 20px;
      button {
        color: #999;
        margin: 0;
      }
      svg {
        font-size: 30px;
      }
    }
  }
}

.hidden {
  display: none;
}

/* search */
.search {
  position: relative;
  margin: 52px 0 40px 0;
  float: right;
  cursor: pointer;
  .input-text {
    width: 407px;
    > div {
      &::before {
        border-bottom: solid 1px #d0d0d0;
      }
      &::after {
        border-bottom: 1px solid $black;
      }
    }
    input {
      padding: 0 0 11px 9px;
      font-weight: 300;
      font-size: 18px;
      color: #777;
    }
    &::placeholder {
      color: #b5b5b5;
    }
  }
  .btn-search {
    i {
      content: '';
      position: absolute;
      top: -5px;
      right: 0;
      display: inline-block;
      width: 27px;
      height: 26px;
      background: url(../assets/images/icon/icon-search.png) no-repeat;
    }
  }
}

/* category */
.category {
  p {
    font-size: 24px;
    font-weight: bold;
  }
  span {
    font-family: Poppins;
    font-weight: 600;
    color: #d5d5d5;
    margin-left: 17px;
  }
}

.left {
  float: left;
}
.right {
  float: right;
}


.table {
  border-top: 1px solid $black;
  tr {
    th, td {
      padding: 16px 0 20px 0;
      &:first-child{
        padding-left: 27px;
      }
      &:last-child{
        padding-right: 27px;
      }
    }
  }
  thead {
    tr {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      &:first-child {
        th {
          border-bottom: none;
        }
      }
      th {
        font-size: 13px;
        background: #fbfbfb;
      }
    }
  }
  tbody {
    tr {
      height: 60px;
      th {
        font-size: 15px;;
      }
    }
  }
}

.board {
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  .board-title {
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid #eee;
    ul {
      width: 100%;
      display: inline-block;
      .category {
        color: #666;
      }
      .active {
        color: #0062b8;
      }
      li {
        display: inline-block;
        font-size: 15px;
        &:first-child {
          margin-right: 30px;
          font-weight: bold;
          @media (max-width: 767px) {
            margin-right: 0;
          }
        }
        &:nth-child(2) {
          font-weight: bold;
        }
        &:last-child {
          font-family: Poppins;
          float: right;
          color: #666666;
          font-weight: normal;
        }
        span {
          margin-left: 8px;
          color: #666666;
        }
      }
    }
  }
  .board-contents {
    // padding: 84px 0 330px 0;
    min-height: 400px;
    padding: 20px 5px;
    box-sizing: border-box;
    p {
      font-size: 15px;
      line-height: 1.67;
      letter-spacing: -0.3px;
    }
  }
}

button {
  h2 {
    display: none;
    overflow: hidden;
  }
}


.m-pagination {
  display: none;
  clear: both;
  text-align: center;
  margin: 30px 0 67px 0;
  p {
    font-size: 16px;
    color: #888;
    span {
      font-family: Poppins;
    }
    .btn-more {
      margin-left: 10px;
      &::before {
        content: '';
        display: inline-block;
        width: 31px;
        height: 14px;
        vertical-align: middle;
        background: url(../assets/images/icon/icon-arrow-down03.png) no-repeat;
      }
      h1 {
        display: none;
      }
    }
  }
}

.move-back {
  &::before {
    content: '';
    width: 9px;
    height: 15px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    background: url(../assets/images/icon/icon-arrow-left.png) no-repeat;
  }
}
.m-move-back {
  display: none;
  &::before {
    content: '';
    width: 9px;
    height: 15px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    background: url(../assets/images/icon/icon-arrow-left-white.png) no-repeat;
  }
}


//editer style custom
.board{
  em{font-style: italic;}
  ol,ul{
    list-style-position: inside;
  }
  ul{list-style-type: circle;}
  ol{list-style-type: decimal;}
  p{
    direction: ltr;
    word-break: break-word;

    strong{font-weight: bold;}
  }
  
  .ql-editor {
    min-height: 400px;
  }

  .ql-align-center {
    text-align: center;
    margin: auto;
  }
  .ql-align-right {
    text-align: right;
    margin-right: 0;
  }
  
  .ql-align-center img {
    width: auto;
    margin: auto;
  }
  .ql-align-right img {
    width: auto;
    margin: auto;
    margin-right: 0;
  }

  .board-contents{
    &>div{position: relative;}
  }

  .ql-video{
      // -- 동영상 크기 -----
      // SD      : 320 x 240, 
      // VGA     : 640 x 480,
      // DVD(D1) : 720 x 480,
      // HD      : 1280 x 720
      // FULL HD : 1920 x 1080
      width: 720px;
      height: 480px;
      position: absolute;
      &.ql-align-center{
        left: 50%;
        transform: translateX(-50%);
      }
      &.ql-align-right{
        right: 0;
        transform: translateX(0);
      }
      
    }
    .ql-video+p{
      padding-top: 480px;
    }
}


// input::radio btn library custom
// svg
input[type=radio]+div{
  .MuiSvgIcon-root{
    path{
      fill:#3c3c3c !important; //#dedede
    }
    &.PrivateRadioButtonIcon-layer-6, &.jss6{
      path{
        fill:$black;
      }
      @media screen and (max-width: 769px) {
        path{
          fill:#1492ff;
        }
      }
    }
  }  
}
.MuiRadio-root{color: $black;}

// selectBox fixed animation laber 
// display none
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-marginDense.MuiFormLabel-filled{
  display: none !important;
}
 
.policy-box,
.privacy-policy{
  p{
    span{
      padding-left:5px;
      display: block;
    }
    .span-table{
      display: table;
      border: 1px solid #888;
      padding: 0;margin:0;
      &-tr{
        display: table-row;
      }
      &-td{
        display: table-cell;
        padding: 5px;
      }
      .l-right{border-right: 1px solid #888;}
      .l-bottom{border-bottom: 1px solid #888;}
      .l-left{border-left: 1px solid #888;}
      .l-top{border-top: 1px solid #888;}
    }
  }
}

.input-address.disable{
  border:0;
  background: #f5f4f4;
  &:focus{
    outline: none;
  }
}


@media screen and (max-width: 769px) {
  body{
    overflow: hidden;
  }
}



/*********** 
* [ 2021-03-04 ]
* 소제목 영문 삭제처리 
*************/
// [forum] 소개, 의장, 조직도
.forum-page.introduce>.inner>.left-contents>h1+h3,
.forum-page>.inner>.left-area>h1+h3,
.forum-page .organization-page .organization-chart>.left>h1+h3,
.forum-page.manifesto .manifesto-sub-title{
  display: none !important;
}
// [membership] 회원사혜택, 연회비, 회원사 상세, 회원사, 제휴클럽 상세
.member-page .benefit-inner>.inner.title>.left-contents>h1+h3,
.member-page .left-contents.title>h1+h3,
.member-page .notice .category p span,
.member-page .title .left-contents h1+h3,
.category p span{
  display: none !important;
}
// [회원가입] 가입신청, 가입안내, 
.join-page .join-info .join-now{
  display: none !important;
}
.apply-page .title h1+p{
  text-indent: -9999px !important;
}
//[커뮤니티] 협력제안, 제휴클럽
.community-page .title>h1+h3,
.news-page .notice>.left>h1+h3,
.news-page .notice .title h1+h3{
  display: none !important;
}
//[mypage]
.my-page .inner .title h3{
  display: none !important;
}
//[영문] history
.forum-page.en .history-title + h6{display: none !important;}