@charset "utf-8";

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import "./styles/colors.scss";
@import "./styles/reset.scss";
@import "./styles/global.scss";
@import "./styles/animate.scss";

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf)
      format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf)
      format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf)
      format("opentype");
  font-display: swap;
}
@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.googleapis.com/css?family=Poppins);

}
@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: bold;
  font-weight: bold;
  src: url(//fonts.googleapis.com/css?family=Poppins);
}


@mixin miniPhone {
  @media (max-width: 429px) {
    @content;
  }
}
// 모바일만
@mixin phone {
  @media (max-width: 767px) {
    @content;
  }
}
// 모바일 이상
@mixin overPhone {
  @media (min-width: 768px) {
    @content;
  }
}
//  태블릿 PC만
@mixin tablet {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
// tabet 이상
@mixin onlyDesktop {
  @media (min-width: 1025px) {
    @content;
  }
}
// tablet Pro 이상
@mixin tabletPro {
  @media (min-width: 1024px) and (max-width: 1366px) {
    @content;
  }
}

// PC 이하
@mixin underDesktop {
  @media (max-width: 1024px) {
    @content;
    @include phone;
  }
}
//2020-12-22 PC 1360 이하
@mixin underGuide-m {
  @media (max-width: 1360px) {
    @content;
    @include phone;
  }
}
// PC 1400 이하
@mixin underGuide {
  @media (max-width: 1400px) {
    @content;
    @include phone;
  }
}
// PC만
@mixin desktop {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin maxDesktop {
  @media (min-width: 1980px) {
    @content;
  }
}

html {
  scroll-behavior: smooth;
}
body {
  color: $black;
  -webkit-overflow-scrolling: touch;
}
.opacity {
  @include desktop {
    opacity: 0;
  }
}

@mixin maxWid(
  $wid:85%,
  $maxW:1280px
){
  width:$wid;
  margin:0 auto;
  max-width:$maxW;
}

